<template>    
    <div class="app">                                           
        <first-header></first-header>    
        <notification-header></notification-header>    
        <ul class="full-width" id="smallhmenu">
            <li class="float-right"><img src="images/menu.svg" alt="" id="hmenulink"></li>
            <li class="float-left"><input type="text" ></li>
        </ul>
        <hmenu :links="links"></hmenu>
        <vmenu></vmenu>
        <main>
            <div class="ghost-block">
                <component class="centered" v-bind:is="componentName"></component>
            </div>
        </main>
        <signout-form></signout-form>
         
    </div>            
</template>
<script>
import FirstHeader from '../components/FirstHeader.vue';
import Hmenu from '../components/Hmenu.vue';
import Vmenu from '../components/Vmenu.vue'
import EmptyComponent from '../components/EmptyComponent.vue';
//import CalendarService from '../components/services/diary/CalendarService.vue';
import SigninForm from '../components/SigninForm.vue';
import SignoutForm from '../components/SignoutForm.vue';
import Slider from '../components/Slider.vue';
import NotificationHeader from '../components/NotificationHeader.vue';

import OfflineLinkJson from '../offlineLinkJson.json';

export default {
    computed:{
        componentName(){
            return this.$store.state.mainComponentName;
        },
        links(){
            return this.$store.state.links;
        }
    },
    components: {
        FirstHeader,
        Hmenu, 
        Vmenu,
        EmptyComponent,
        //CalendarService, 
        SigninForm,
        SignoutForm,
        Slider,
        NotificationHeader
    },
    beforeCreate() {                     
        this.$store.commit('links', OfflineLinkJson);
    },
}
</script>
<style scoped>
	@media only screen and (max-width: 767px){
		ul#smallhmenu{
			display: inline-block;
			list-style: none;
			margin: 0;
			padding: 0;        
			background-color: #009966;   
			overflow: hidden;     
		}    

		ul#smallhmenu > li > input{
			margin-top: 3px;
			margin-left: 4px;
			
		}

		ul#smallhmenu > li > img{       
			width: 30px;     
			margin-top: 4px;
			margin-bottom: 6px;
			margin-right: 4px;
		}
	}

	@media only screen and (min-width: 768px){
		ul#smallhmenu{
            display:    none;
        }

        ul#firstheader{
            display: inline-block;
            list-style: none;  
            margin: 0; 
            padding: 0;    
            overflow: auto; 
            font-size: 0;
        }
        
        ul#firstheader > li {   
            margin: 0;
            padding: 0;
        }
        
        ul#firstheader > li:not(#logo){   
            margin-top: 2px;
            margin-left: 4px;  
            padding-top: 4px; 
            padding-bottom: 4px; 
        }
                
        ul#firstheader > li:not(#logo) > a{
            background: #009966;
            font-weight: bold;
            color: #fff; 
            cursor: pointer;                
            padding: 4px;
            text-align: center;
            text-shadow: 1px 1px 1px #000;
            border-radius: 3px;
            text-decoration: none;
            font-size: var(--menu-font-size);
        }        
        
        ul#firstheader > li:not(#logo) > a:hover{
            background: #725162;
            color: white;
        }
        ul#firstheader > li:not(#logo) > a.selected{
            outline: 0;
            background: #ffa700;
            color: white;
        }

    }
    
    main{    
        /* display: inline-block;     */
        margin: 0;
        padding: 0;    
        /* min-width: 100%; */
        height: calc(100vh - 93px);
        font-size: 12px;
        text-align: center;
        background: yellow;
        /* background-color: #0099668f; */
    }
</style>


