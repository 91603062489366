<template>
    <div v-if="signedIn" class="full-height">        
        <logged-in></logged-in>
    </div>
    <div v-else class="full-height">
         <guest></guest>   
    </div>    
</template>
<script>
import Guest from './Guest.vue';
import LoggedIn from './LoggedIn.vue';
//import AppConfig from './config/AppConfig.vue';
export default {
    computed:{
        signedIn(){
            return this.$store.state.userId != 0; 
        }
    },
    components:{
        LoggedIn,
        //AppConfig,
        Guest
    }
}
</script>
<style scoped>
    @media only screen and (max-width: 1px){
        ul#firstheader{
            display: inline-block;
            list-style: none;  
            margin: 0; 
            padding: 0;    
            overflow: auto; 
            font-size: 0;
        }

        ul#firstheader > li {   
            margin: 0;
            padding: 0;
        }

        ul#firstheader > li#logo > picture > img{   
            margin: 0;
            padding: 0;
            height: 60px;
            width: 100px;
            object-fit:fill;    
        }

        ul#firstheader > li:not(#logo){   
            margin-top: 2px;
            margin-left: 4px;  
            padding-top: 4px; 
            padding-bottom: 4px; 
        }
                
        ul#firstheader > li:not(#logo) > a{
            background: #009966;
            font-weight: bold;
            color: #fff; 
            cursor: pointer;                
            padding: 4px;
            text-align: center;
            text-shadow: 1px 1px 1px #000;
            border-radius: 3px;
            text-decoration: none;
            font-size: var(--mobile-menu-font-size);
        }        

        ul#firstheader > li:not(#logo) > a:hover{
            background: #725162;
            color: white;
        }

        ul#firstheader > li:not(#logo) > a.selected{
            outline: 0;
            background: #ffa700;
            color: white;
        }   
    }

    @media only screen and (min-width: 1px){
        ul#firstheader{
            display: inline-block;
            list-style: none;  
            margin: 0; 
            padding: 0;    
            overflow: auto; 
            font-size: 0;
        }
        
        ul#firstheader > li {   
            margin: 0;
            padding: 0;
        }
        
        ul#firstheader > li:not(#logo){   
            margin-top: 2px;
            margin-left: 4px;  
            padding-top: 4px; 
            padding-bottom: 4px; 
        }
                
        ul#firstheader > li:not(#logo) > a{
            background: #009966;
            font-weight: bold;
            color: #fff; 
            cursor: pointer;                
            padding: 4px;
            text-align: center;
            text-shadow: 1px 1px 1px #000;
            border-radius: 3px;
            text-decoration: none;
            font-size: var(--menu-font-size);
        }        
        
        ul#firstheader > li:not(#logo) > a:hover{
            background: #725162;
            color: white;
        }
        ul#firstheader > li:not(#logo) > a.selected{
            outline: 0;
            background: #ffa700;
            color: white;
        }

    }

    #slider{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 250px;
        height: 250px;
        margin-top: -125px; /* Half the height */
        margin-left: -125px; /* Half the width */
        vertical-align: center;
        text-align: center;

    }
</style>
