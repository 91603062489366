<template>
    <div>
        <div class="topbar">
            <first-header></first-header>   
            <!-- <notification-header></notification-header>     -->
            <!-- <ul class="full-width" id="smallhmenu">
                <li class="float-right"><img src="@/assets/images/menu.svg" id="hmenulink" alt="Menu"></li>
                <li class="float-left"><input type="text" ></li>
            </ul> -->
            <hmenu :links="links"></hmenu>
        </div>
        <vmenu></vmenu>
        <main>
            <div class="ghost-block">
                <content-area></content-area>
                <!-- <component class="centered" v-bind:is="componentName"></component> -->
            </div>
        </main>
        <signin-form></signin-form>
        <signout-form></signout-form>
    </div>
</template>

<script>
import FirstHeader from '../components/FirstHeader.vue';
import SigninForm from '../components/SigninForm.vue';
import SignoutForm from '../components/SignoutForm.vue';
import Hmenu from '../components/Hmenu.vue';
import Vmenu from '../components/Vmenu.vue'
import ContentArea from '../components/Content.vue';
import OfflineLinkJson from '../offlineLinkJson.json';
import Cities from '../cities.json';

export default {
    computed:{
        componentName(){
            return this.$store.state.mainComponentName;
        },
        links(){
            return this.$store.state.links;
        }
    },
    components:{
        FirstHeader,
        SigninForm,
        SignoutForm,
        Hmenu, 
        Vmenu,
        ContentArea,
    },
    beforeCreate() {                     
        this.$store.commit('links', OfflineLinkJson.menus);
        this.$store.commit('zodiacSigns', OfflineLinkJson.zodiacSigns);
        this.$store.commit('cities', Cities.cities);
    }, 
}
</script>
<style scoped>
	.topbar{
        background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(	90deg,#19355c 0,#19355c 74px, #19355c 0, #19355c);
        border-bottom: 5px solid #ffd55a;
	}
    @media only screen and (max-width: 1px){
		ul#smallhmenu{
			display: inline-block;
			list-style: none;
			margin: 0;
			padding: 0;        
			background-color: #009966;   
			overflow: hidden;     
		}    

		ul#smallhmenu > li > input{
			margin-top: 3px;
			margin-left: 4px;
			
		}

		ul#smallhmenu > li > img{       
			width: 30px;     
			margin-top: 4px;
			margin-bottom: 6px;
			margin-right: 4px;
		}
	}

	@media only screen and (min-width: 2px){
		ul#smallhmenu{
            display:    none;
        }
    }
    
    /* main{    
        margin: 0;
        padding: 0;    
        height: calc(100vh - 93px);
        font-size: 12px;
        text-align: center;
        background: yellow;
    } */
</style>
